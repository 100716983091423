import React, { useState, useEffect } from "react";
import http from "./lib/http";
import "./App.css";
import { TopicContext } from "./components/TopicContext";
import Carousel from "./components/Carousel";
import { NavBar } from "./components/NavBar";
import { IntroSlide } from "./components/IntroSlide";
import Media from "./components/mediaContentWindow";
import SplashScreen from "./components/SplashScreen";

export default function App() {
  const [expertise, setExpertise] = useState([{}]);
  const [inspiration, setInspiration] = useState([{}]);
  const [partnerships, setPartnerships] = useState([{}]);
  const [topicType, setTopicType] = useState("expertise");
  const [topicChosen, setTopicChosen] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(3);
  const [itemChosen, setItemChosen] = useState(false);
  //eslint-disable-next-line
  const [showSplash, setShowSplash] = useState(true);
  const navBarTopics = Array.from(
    document.getElementsByClassName("navigation-link"),
  );
  const init = () =>
    navBarTopics.forEach((el) => {
      el.classList.add("selected");
      el.id !== topicType
        ? el.classList.remove("selected")
        : el.classList.add("selected");
    });
  useEffect(() => {
    async function fetchData() {
      const experts = await http.get(`/api/expertise`);
      const pets = await http.get(`/api/inspiration`);
      const partners = await http.get(`/api/partnerships`);

      setExpertise(experts.data.experts);
      setInspiration(pets.data.pets);
      setPartnerships(partners.data.partners);
    }
    fetchData();
    return () => {};
  }, []);
  return (
    init(),
    (
      <TopicContext.Provider
        value={{
          topicType,
          setTopicType,
          topicChosen,
          setTopicChosen,
          carouselIndex,
          setCarouselIndex,
          itemChosen,
          setItemChosen,
          showSplash,
          setShowSplash,
          expertise,
          setExpertise,
          inspiration,
          setInspiration,
          partnerships,
          setPartnerships,
        }}
      >
        {topicChosen ? (
          <div className="App">
            <NavBar />
            <Media />
            <Carousel />
          </div>
        ) : (
          <div className="App">
            <SplashScreen />
            <IntroSlide />
          </div>
        )}
      </TopicContext.Provider>
    )
  );
}
